var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-spinner":"el-icon-loading","element-loading-text":"玩命加载中..."}},[_c('div',{staticStyle:{"border-bottom":"2px solid #dddddd"}},[_c('div',{staticClass:"login-main-top"},[_vm._m(0),_c('span',{staticStyle:{"position":"absolute","right":"0"}},[_c('span',{staticClass:"buttom-txt register-goLogin",staticStyle:{"margin-right":"10px"},on:{"click":function($event){return _vm.goNew('/')}}},[_vm._v("[返回首页]")]),_vm._v(" hi，欢迎来到嘉兴智行物联网技术有限公司！ ")])])]),_c('div',{staticClass:"login-container"},[_c('img',{staticStyle:{"height":"100%"},attrs:{"alt":"logo","src":require("../assets/image.png")}}),_c('div',{staticClass:"login-form"},[_vm._m(1),_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"账号","prop":"username","rules":[
          { required: true, message: '请输入账号', trigger: 'blur' },
        ]}},[_c('el-input',{model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1),_c('el-form-item',{attrs:{"label":"密码","prop":"password","rules":[
          { required: true, message: '请输入密码', trigger: 'blur' },
        ]}},[_c('el-input',{attrs:{"show-password":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('el-form-item',{staticStyle:{"position":"relative"}},[_c('el-button',{staticStyle:{"width":"100%"},attrs:{"type":"primary","size":"small"},on:{"click":_vm.goLogin}},[_vm._v("立即登录")]),_c('span',{staticClass:"buttom-txt register-goLogin",staticStyle:{"position":"absolute","right":"0","top":"40px"},on:{"click":function($event){return _vm.goReplace('/register')}}},[_vm._v("立即注册")])],1)],1)],1)]),_vm._m(2),_vm._m(3)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-logo"},[_c('img',{attrs:{"alt":"logo","src":require("../../public/LOGO.svg")}}),_c('span',[_vm._v("嘉兴智行物联网技术有限公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-form-top"},[_c('div',{staticClass:"search-form-logo"},[_c('img',{attrs:{"alt":"logo","src":require("../../public/LOGO.svg")}}),_c('span',[_vm._v("嘉兴智行物联网技术有限公司")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"buttom-txt"},[_vm._v("© 嘉兴智行物联网技术有限公司 "),_c('a',{staticClass:"a-herf",attrs:{"href":"http://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("浙ICP备15017943号-6 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"buttom-txt"},[_vm._v("地址：浙江省嘉兴市南湖区智慧产业园9幢5楼 "),_c('span',{staticStyle:{"margin-left":"20px"}},[_vm._v("客服电话：400-667-9990")])])
}]

export { render, staticRenderFns }