<template>
  <div v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="玩命加载中...">
    <div style="border-bottom: 2px solid #dddddd;">
      <div class="login-main-top">
        <div class="search-logo">
          <img alt="logo" src="../../public/LOGO.svg">
          <!-- <span>闪开</span><span style="font-family: FZLanTingHeiS-R-GB;">·</span><span>新媒</span> -->
          <span>嘉兴智行物联网技术有限公司</span>
        </div>
        <span style="position: absolute;right: 0;">
          <span class="buttom-txt register-goLogin" style="margin-right: 10px;" @click="goNew('/')">[返回首页]</span>
          hi，欢迎来到嘉兴智行物联网技术有限公司！
        </span>
      </div>
    </div>

    <div class="login-container">
      <img alt="logo" src="../assets/image.png" style="height: 100%;">
      <div class="login-form">
        <div class="login-form-top">
          <div class="search-form-logo">
            <img alt="logo" src="../../public/LOGO.svg">
            <!-- <span>闪开</span><span style="font-family: FZLanTingHeiS-R-GB;">·</span><span>新媒</span> -->
            <span>嘉兴智行物联网技术有限公司</span>
          </div>
        </div>
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="账号" prop="username" :rules="[
            { required: true, message: '请输入账号', trigger: 'blur' },
          ]">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" :rules="[
            { required: true, message: '请输入密码', trigger: 'blur' },
          ]">
            <el-input v-model="form.password" show-password></el-input>
          </el-form-item>
          <el-form-item style="position: relative;">
            <el-button type="primary" size="small" @click="goLogin" style="width: 100%;">立即登录</el-button>
            <!-- <span style="position: absolute;left: 0;top:40px;cursor: pointer; color: red;">忘记密码</span> -->
            <span style="position: absolute;right: 0;top:40px;" class="buttom-txt register-goLogin"
              @click="goReplace('/register')">立即注册</span>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <p class="buttom-txt">© 嘉兴智行物联网技术有限公司   <a href="http://beian.miit.gov.cn/" target="_blank" class="a-herf">浙ICP备15017943号-6 </a></p>
    <p class="buttom-txt">地址：浙江省嘉兴市南湖区智慧产业园9幢5楼 <span style="margin-left: 20px;">客服电话：400-667-9990</span></p>
  </div>
</template>
    
<script>

import { encrypt } from '@/utils/tools'
export default {
  name: 'Login',
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      loading: false
    }
  },
  created() {
  },
  methods: {
    goNew(val) {
      if (this.$route.path !== val) {
        this.$router.push(val);
      }
    },
    goReplace(val) {
      if (this.$route.path !== val) {
        this.$router.replace(val);
      }
    },
    goLogin() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$api.login(this.form).then((res) => {
            // // 将 token 存储到本地（这里使用 localStorage）
            localStorage.setItem('token', encrypt(this.form.username))
            localStorage.setItem('userId', res.data)
            this.goNew('/me')
          }).finally(() => {
            this.loading = false
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>
     
<style scoped>
.login-main-top {
  position: relative;
  width: 80%;
  height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.login-form-top {
  position: relative;
  width: 100%;
  height: 80px;
  margin: -60px auto 10px;
  padding: 10px 0;
  text-align: center;
}

.login-container {
  display: flex;
  justify-content: center;
  /* 水平居中对齐 */
  align-items: center;
  /* 垂直居中对齐 */
  width: 80%;
  height: 400px;
  margin: 0 auto;
  /* 居中 */
  padding: 30px 0;
}

.search-logo {
  position: absolute;
  left: 0;
}

.search-logo img,
.search-form-logo img {
  margin-top: 10px;
  margin-right: -30px;
  vertical-align: middle;
}

.search-form-logo img {
  height: 90px;
  padding-right: 10px;
}

.search-logo span,
.search-form-logo span {
  color: #2A2A2A;
  font-weight: 400;
  font-size: 24px;
  vertical-align: middle;
  font-family: YouYuan;
  margin-right: 5px;
}

.search-form-logo span {
  font-size: 20px;
}

.login-form {
  text-align: center;
  border: 1px solid #dddddd;
  height: 100%;
  padding: 20px 40px 20px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.el-button--primary {
  background-color: #83b24c;
  border-color: #83b24c;
}

.el-button--primary:focus,
.el-button--primary:hover {
  background: rgb(172, 204, 137);
  border-color: rgb(172, 204, 137);
  color: #FFF;
}

.register-goLogin {
  cursor: pointer;
  text-decoration: underline;
}

.register-goLogin:hover {
  color: red;
}

.buttom-txt {
  text-align: center;
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  color: #9E9E9E;
}

.a-herf{
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  color: #9E9E9E;
  text-align: center;
}

.a-herf:hover {
  color: #6F9B3C;
}
</style>

<style>
.login-form>.el-form-item>.el-form-item__label:before {
  display: none;
}
</style>